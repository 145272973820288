<template>
  <div class="row">
        <div class="col-md-12">
            <fieldset class="p-2 border">
                <legend class="w-auto">Stammdaten</legend>
                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group row">
                            <label for="distributor_id" class="control-label col-sm-2 col-form-label col-form-label-sm">Shop</label>
                            <div class="col-sm-9">
                                <select name="company_id" id="company_id" class="form-control form-control-sm" v-model="form.company_id" :class="{'is-invalid': form.errors.has('company_id')}" :disabled="true">
                                    <option v-bind:value="customer.company.id">{{customer.company.name}}</option>
                                </select>
                                <has-error :form="form" field="company_id"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="customernumber" class="control-label col-sm-2 col-form-label col-form-label-sm">Kundennummer</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" name="customernumber" id="customernumber" :class="{'is-invalid': form.errors.has('customernumber')}" v-model="form.customernumber" :disabled="true">
                                <has-error :form="form" field="customernumber"></has-error>
                            </div>
                        </div>
                        <br>

                        <div class="form-group row">
                            <label for="active" class="control-label col-sm-2 col-form-label col-form-label-sm">Aktiv?*</label>
                            <div class="col-sm-9">
                                <select name="active" id="active" class="form-control form-control-sm" v-model="form.active" :class="{'is-invalid': form.errors.has('active')}">
                                    <option value="1">Ja</option>
                                    <option value="0">Nein</option>
                                </select>
                                <has-error :form="form" field="active"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="changePassword">
                            <label for="password" class="control-label col-sm-2 col-form-label col-form-label-sm">Passwort*</label>
                            <div class="col-sm-9">
                                <input type="password" autocomplete="new-password" name="password" id="password" class="form-control form-control-sm" v-model="form.password" :class="{'is-invalid': form.errors.has('password')}">
                                <has-error :form="form" field="password"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="customer.website != null && customer.website != ''">
                            <label for="website" class="control-label col-sm-2 col-form-label col-form-label-sm">Website</label>
                            <div class="col-sm-9">
                                <input type="website" autocomplete="username" class="form-control form-control-sm" name="website" id="website" :class="{'is-invalid': form.errors.has('website')}" v-model="form.website" :disabled="true">
                                <has-error :form="form" field="website"></has-error>
                            </div>
                        </div>

                        <div class="form-group row align-items-center">
                            <label for="forbid_invoice" class="control-label col-sm-3 col-form-label col-form-label-sm">Rechnungskauf verbieten <i class="fas fa-ban" style="color: red"></i></label>
                            <div class="col-sm-8">
                                <input type="checkbox" class="form-check-input" name="forbid_invoice" id="forbid_invoice" :class="{'is-invalid': form.errors.has('forbid_invoice')}" v-model="form.forbid_invoice" style="position: static; margin-left: 0;">
                                <has-error :form="form" field="forbid_invoice"></has-error>
                            </div>
                        </div>

                        <div class="form-group row align-items-center">
                            <label for="allow_invoice" class="control-label col-sm-3 col-form-label col-form-label-sm">Rechnungskauf ab 1.&nbsp;Bestellung erlauben <i class="far fa-check-circle" style="color: green"></i></label>
                            <div class="col-sm-8">
                                <input type="checkbox" class="form-check-input" name="allow_invoice" id="allow_invoice" :class="{'is-invalid': form.errors.has('allow_invoice')}" v-model="form.allow_invoice" style="position: static; margin-left: 0;">
                                <has-error :form="form" field="allow_invoice"></has-error>
                            </div>
                        </div>

                        <div class="form-group row align-items-center">
                            <label for="allow_invoice" class="control-label col-sm-3 col-form-label col-form-label-sm">Sepa-Lastschrift erlauben <i class="far fa-check-circle" style="color: green"></i></label>
                            <div class="col-sm-8">
                                <input type="checkbox" class="form-check-input" name="allow_sepa" id="allow_sepa" :class="{'is-invalid': form.errors.has('allow_sepa')}" v-model="form.allow_sepa" style="position: static; margin-left: 0;">
                                <has-error :form="form" field="allow_sepa"></has-error>
                            </div>
                        </div>

                
                    </div>

                    <div class="col-md-6">

                        <div class="form-group row">
                            <label for="customer_group_id" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Kundengruppe*</label>
                            <div class="col-sm-9">
                                <select name="customer_group_id" id="customer_group_id" class="form-control form-control-sm" @change="changeGroup($event)" v-model="form.customer_group_id" :class="{'is-invalid': form.errors.has('customer_group_id')}">
                                    <option v-for="group in customer.company.groups" :key="group.id" v-bind:value="group.id">{{group.description}}</option>
                                </select>
                                <has-error :form="form" field="customer_group_id"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="email" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">E-Mail*</label>
                            <div class="col-sm-9">
                                <input type="email" autocomplete="username" class="form-control form-control-sm" name="email" id="email" :class="{'is-invalid': form.errors.has('email')}" v-model="form.email">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="useAdditionalInvoice == true">
                            <label for="invoice_email" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Rechnungs-E-Mail</label>
                            <div class="col-sm-9">
                                <input type="invoice_email" autocomplete="username" class="form-control form-control-sm" name="invoice_email" id="invoice_email" :class="{'is-invalid': form.errors.has('invoice_email')}" v-model="form.invoice_email">
                                <has-error :form="form" field="invoice_email"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="active" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Passwort ändern?</label>
                            <div class="col-sm-9">
                                <select v-model="changePassword" class="form-control form-control-sm">
                                    <option v-bind:value=true>Ja</option>
                                    <option v-bind:value=false>Nein</option>
                                </select>
                                <has-error :form="form" field="active"></has-error>
                            </div>
                        </div>
                        
                        <div class="form-group row" v-if="changePassword">
                            <label for="password_confirmation" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Passwort wiederholen*</label>
                            <div class="col-sm-9">
                                <input type="password" autocomplete="new-password" name="password_confirmation" id="password_confirmation" class="form-control form-control-sm" v-model="form.password_confirmation" :class="{'is-invalid': form.errors.has('password_confirmation')}">
                                <has-error :form="form" field="password_confirmation"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="active" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Aktionen</label>
                            <div class="col-sm-9">
                                <router-link :to="{name: 'orders.create', params: {id: customer.id}}" class="mr-1 btn btn-primary btn-sm">Neue Bestellung</router-link>
                                <!-- <router-link :to="{name: 'credits.create'}" class="mr-1 btn btn-primary btn-sm">Neue Gutschrift</router-link>
                                <router-link :to="{name: 'offers.create'}" class="mr-1 btn btn-primary btn-sm">Neues Angebot</router-link> -->
                            </div>           
                        </div>

                        <div class="form-group row align-items-center">
                            <label style="color: #499360;" for="allow_invoice" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">InterCompany Kunde <i class="far fa-check-circle" style="color: green"></i></label>
                            <div class="col-sm-8">
                                <input type="checkbox" class="form-check-input" name="inter_company" id="inter_company" :class="{'is-invalid': form.errors.has('inter_company')}" v-model="form.inter_company" style="position: static; margin-left: 0;">
                                <has-error :form="form" field="inter_company"></has-error>
                            </div>
                        </div>

                        <div class="form-group row align-items-center" v-if="form.inter_company == 1 || form.inter_company == true">
                            <label style="color: #499360;" for="allow_invoice" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">InterCompany Nummer <i class="far fa-check-circle" style="color: green"></i></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-check-input" name="inter_company_number" id="inter_company_number" :class="{'is-invalid': form.errors.has('inter_company_number')}" v-model="form.inter_company_number" style="position: static; margin-left: 0;">
                                <has-error :form="form" field="inter_company_number"></has-error>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="p-2 mt-3 border">
                <legend class="w-auto">Persönliche Daten</legend>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="first_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" name="first_name" id="first_name" :class="{'is-invalid': form.errors.has('first_name')}" v-model="form.first_name">
                                <has-error :form="form" field="first_name"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="birthday" class="control-label col-sm-2 col-form-label col-form-label-sm">Geburtstag</label>
                            <div class="col-sm-9">
                                <input type="date" class="form-control form-control-sm" name="birthday" id="birthday" :class="{'is-invalid': form.errors.has('birthday')}" v-model="form.birthday">
                                <has-error :form="form" field="birthday"></has-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="last_name" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Nachname*</label>
                            <div class="col-sm-9">
                                <input type="text" name="last_name" id="last_name" class="form-control form-control-sm" v-model="form.last_name" :class="{'is-invalid': form.errors.has('last_name')}">
                                <has-error :form="form" field="last_name"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="discount" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Rabatt</label>
                            <div class="col-sm-9">
                                <input type="text" name="discount" id="discount" class="form-control form-control-sm" v-model="form.discount" :class="{'is-invalid': form.errors.has('discount')}">
                                <has-error :form="form" field="discount"></has-error>
                            </div>
                        </div>
                    </div>  
                </div>

            </fieldset>

            <div class="mt-3 row">
                <div class="col-md-6">
                    <fieldset class="p-2 border">
                    <legend class="w-auto">Standard-Rechnungsadresse</legend>
                        <div class="col-md-12">
                            <span v-if="customer.billing_address.company != null">
                            {{ customer.billing_address.company }}<br>
                                <span v-if="customer.billing_address.department != null">
                                    {{ customer.billing_address.department }}<br>
                                </span>    
                            </span>
                            <span v-if="customer.billing_address.kostenstelle != null">
                                {{ customer.billing_address.kostenstelle }} <br>
                            </span>
                            {{customer.billing_address.first_name}} {{customer.billing_address.last_name}} <br>
                            {{customer.billing_address.street}} {{customer.billing_address.house_number}} <br>
                            {{customer.billing_address.zipcode}} {{customer.billing_address.city}} <br>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-6">
                    <fieldset class="p-2 border">
                    <legend class="w-auto">Standard-Lieferadresse</legend>
                        <div class="col-md-12">
                            <span v-if="customer.shipping_address.company != null">
                            {{ customer.shipping_address.company }}<br>
                                <span v-if="customer.shipping_address.department != null">
                                    {{ customer.shipping_address.department }}<br>
                                </span>    
                            </span>
                            <span v-if="customer.shipping_address.kostenstelle != null">
                                {{ customer.shipping_address.kostenstelle }} <br>
                            </span>
                            {{customer.shipping_address.first_name}} {{customer.shipping_address.last_name}} <br>
                            {{customer.shipping_address.street}} {{customer.shipping_address.house_number}} <br>
                            {{customer.shipping_address.zipcode}} {{customer.shipping_address.city}} <br>
                        </div>
                    </fieldset>
                </div>
            </div>
            <!-- <fieldset class="p-2 mt-3 border">
                <legend class="w-auto">Zahlungsdaten</legend>
                <h6>Coming Soon</h6>
            </fieldset> -->

            <fieldset class="p-2 mt-3 border">
                <legend class="w-auto">Interne Kommentar</legend>
                <div class="col-sm-9">
                    <ckeditor :editor="editor" :config="editorConfig" v-model="form.comment" :class="{'is-invalid': form.errors.has('comment')}"></ckeditor>
                    <has-error :form="form" field="comment"></has-error>
                </div>
            </fieldset>

            <div class="mt-3 row justify-content-end">
                <div class="text-right col-md-12">
                    <button @click="cancel" class="btn btn-light">Abbrechen</button>
                    <button @click="saveCustomer" class="btn btn-primary">Speichern</button>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "CustomersBaseData",
    props: [
        'customer',
    ],
    data(){
        return{
            groups: [],
            companies: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            changePassword: false,
            useAdditionalInvoice: false,
            form: new window.Form({
                company_id: "",
                customer_group_id: "",
                customernumber: "",
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                password_confirmation: "",
                birthday: "",
                comment: "",
                active: "",
                discount: "",
                invoice_email: "", 
                forbid_invoice: "", 
                allow_invoice: "", 
                allow_sepa: "", 
                website: "", 
                inter_company: "",
                inter_company_number: "",
            }),
        }
    },

    methods:{
        
        changeGroup(event){
            // console.log(event.target.value); 
            var group = this.customer.company.groups.find(item => item.id == event.target.value); 
            // console.log(group);
            if(group.use_invoice_email == 0)
            {
                this.useAdditionalInvoice = false;
            } else
            {
                this.useAdditionalInvoice = true;
            }
            // if(group.tax == 1)
            // {
            //     this.useAdditionalInvoice = false; 
            // }
            // else{
            //     this.useAdditionalInvoice = true; 
            // }
        },

        saveCustomer(){
            this.$Progress.start();
            this.form
                .put("/customers/"+ this.$route.params.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Kunde gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.$Progress.finish();
                    this.$parent.loadCustomer();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        cancel(){
            this.$router.push({name: 'customers.index'})
        }
    },

    mounted() {
        if(this.customer.group.use_invoice_email == 0)
        {
            this.useAdditionalInvoice = false;
        } else
        {
            this.useAdditionalInvoice = true;
        }
        // if(this.customer.group.tax == 0)
        // {
        //     this.useAdditionalInvoice = true;
        // }
        if(this.customer.comment == null)
        {
            this.customer.comment = '';
        }
        setTimeout(() => {
            this.form.fill(this.customer);
        });
    }



}
</script>

<style>

</style>